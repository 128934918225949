import { Box, Grid, Text } from '@chakra-ui/react';

import { HeadingTwo } from '@/components/Heading';
import { useUpdateEventStore } from '@/store/update-event';
import { SelectableButton } from '@/components/SelectableButton';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

const templateGridColumnsResponsive = {
  base: 'repeat(2, 1fr)',
  sm: 'repeat(4, 7.875rem)',
  md: 'repeat(4, 6.875rem)',
};

export function RepairTeam() {
  const { selectedCategory, selectedRepairTeam, onSelectRepairTeam } =
    useUpdateEventStore((state) => state);

  const { queryData } = useGetEventDetailToUpdate();

  if (selectedCategory.name === 'Indisponibilidade') {
    return (
      <Box
        p="2rem 1.5rem"
        border="1px solid"
        borderColor="gray.200"
        rounded="0.5rem"
      >
        <HeadingTwo text="Parceiro" />
        <Grid
          templateColumns={templateGridColumnsResponsive}
          gap={{ base: 4, sm: 0, md: 4 }}
          mt="1rem"
        >
          {queryData.parameters.repair_teams.map((team) => (
            <SelectableButton
              key={team.id}
              name={team.name}
              comparisonData={team.id}
              selectedData={selectedRepairTeam ? selectedRepairTeam.id : null}
              onSelect={() => onSelectRepairTeam(team)}
            />
          ))}
        </Grid>

        {!selectedRepairTeam && (
          <Text color="#FF0000" mt="1.5rem">
            *Para atualizar o evento, é necessário selecionar um parceiro.
          </Text>
        )}
      </Box>
    );
  }
}
