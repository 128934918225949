import { List, ListItem } from '@chakra-ui/react';
import { MdMonitor } from 'react-icons/md';

import { NavItem } from './NavItem';
// import { Plus } from '../../../Icons/Plus';
import { Dashboard } from '../../../Icons/Dashboard';
import { Calendar } from '../../../Icons/Calendar';
import { Ticket } from '../../../Icons/Ticket';

const items = [
  // {
  //   type: 'link',
  //   label: 'Criar Evento',
  //   icon: Plus,
  //   path: '/create/event',
  // },
  {
    type: 'link',
    label: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    type: 'accordion',
    label: 'Evento',
    icon: Calendar,
    path: '/',
  },
  {
    type: 'link',
    label: 'Tickets',
    icon: Ticket,
    path: '/tickets',
  },
  {
    type: 'link',
    label: 'Portal',
    icon: MdMonitor,
    path: '/portal',
  },
];

interface NavigationProps {
  collapse: boolean;
}

export function Navigation({ collapse }: NavigationProps) {
  return (
    <List
      w="full"
      display="flex"
      flexDirection="column"
      gap="2rem"
      mt="2.625rem "
    >
      {items.map((item, index) => (
        <ListItem key={index}>
          <NavItem item={item} collapse={collapse} />
        </ListItem>
      ))}
    </List>
  );
}
