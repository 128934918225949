import { Flex, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect } from 'react';
import { parseCookies, setCookie } from 'nookies';
import { toast } from 'react-toastify';

import { apiSSO } from '@/lib/axios/apiSSO';
import { apiReactQuery } from '@/lib/axios/apiReactQuery';
import { useAuth } from '@/hooks/useAuth';
import { apiAxios } from '@/lib/axios/apiAxios';

import logoImg from '@/assets/images/logo.svg';

const urlSSO = `${import.meta.env.VITE_BASE_URL_SSO}/session?origin=${
  import.meta.env.VITE_DOMAIN
}&client_id=${import.meta.env.VITE_CLIENT_ID}`;

export function LoginRedirect() {
  const search = window.location.search;
  const queryParams = queryString.parse(search);
  const navigate = useNavigate();
  const { saveUser } = useAuth();

  useEffect(() => {
    async function signInValidations() {
      const cookies = parseCookies();
      const tokenCookie = cookies['@event-monitor-web:token-1.0.1'];
      const refreshTokenCookie =
        cookies['@event-monitor-web:refreshToken-1.0.1'];

      if (tokenCookie && refreshTokenCookie) {
        navigate('/dashboard');
      }

      if (queryParams.error) {
        toast.error(queryParams.error);

        setTimeout(() => {
          window.location.href = urlSSO;
        }, 5000);
      }

      if (queryParams.access_token && queryParams.refresh_token) {
        apiSSO.defaults.headers.Authorization = `Bearer ${queryParams.access_token}`;
        apiReactQuery.defaults.headers.Authorization = `Bearer ${queryParams.access_token}`;
        apiAxios.defaults.headers.Authorization = `Bearer ${queryParams.access_token}`;

        try {
          const { data } = await apiSSO.get('/user/@me');

          setCookie(
            undefined,
            '@event-monitor-web:token-1.0.1',
            `${queryParams.access_token}`,
            {
              maxAge: 60 * 60 * 24 * 30,
              path: '/',
            },
          );

          setCookie(
            undefined,
            '@event-monitor-web:refreshToken-1.0.1',
            `${queryParams.refresh_token}`,
            {
              maxAge: 60 * 60 * 24 * 30,
              path: '/',
            },
          );

          setCookie(
            undefined,
            '@event-monitor-web:user-1.0.1',
            JSON.stringify(data),
            {
              maxAge: 60 * 60 * 24 * 30,
              path: '/',
            },
          );

          saveUser(data);

          navigate('/dashboard');
        } catch (error) {
          console.error(error);
          toast.error('Erro ao realizar a autenticação');
        }
      }
    }

    signInValidations();
  }, [
    navigate,
    queryParams.access_token,
    queryParams.error,
    queryParams.refresh_token,
    saveUser,
  ]);
  return (
    <Flex
      align="center"
      bg="blue.900"
      h="100vh"
      justify="center"
      px={{ base: '1rem', lg: '0' }}
    >
      <Flex
        bg="white"
        w={{ base: '34.33rem' }}
        rounded="0.625rem"
        py="4rem"
        px={{ base: '2rem', sm: '4rem' }}
        alignItems="center"
        justify="center"
      >
        <Image
          src={logoImg}
          alt="Logo Image"
          w={{ base: '7rem', sm: '9.6rem' }}
          h="4.31rem"
        />
      </Flex>
    </Flex>
  );
}
