import { Routes, Route } from 'react-router-dom';
import { Dashboard } from '@/pages/Dashboard';
import { SignIn } from '@/pages/SignIn';
import { OpenedEvents } from '@/pages/OpenedEvents';
import { PreviousEvents } from '@/../src/pages/PreviousEvent';
import { RedirectOnAuth } from './RedirectOnAuth';
// import { CreateEvent } from '@/pages/CreateEvent';
import { UpdateEvent } from '@/pages/UpdateEvent';
import { EventDetails } from '@/pages/EventDetails';
import { Portal } from '@/pages/Portal';
import { PortalDetails } from '@/pages/Portal/PortalDetails';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { TicketByEvent } from '@/pages/TicketByEvent';
import { Welcome } from '@/pages/Welcome';
import { Tickets } from '@/pages/Tickets';

import { LoginRedirect } from '@/pages/LoginRedirect';
import { PublicEvent } from '@/pages/PublicEvent';

export function MainRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RedirectOnAuth>
            <SignIn />
          </RedirectOnAuth>
        }
      />

      <Route
        path="/login/redirect"
        element={
          <RedirectOnAuth>
            <LoginRedirect />
          </RedirectOnAuth>
        }
      />

      <Route
        path="/"
        element={
          <RedirectOnAuth>
            <DashboardLayout />
          </RedirectOnAuth>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/opened/events" element={<OpenedEvents />} />

        <Route path="/previous/events" element={<PreviousEvents />} />

        {/* <Route path="/create/event" element={<CreateEvent />} /> */}

        <Route path="/update/event/:idEvent" element={<UpdateEvent />} />

        <Route path="/event/details/:idEvent" element={<EventDetails />} />

        <Route path="/ticket/:idEvent" element={<TicketByEvent />} />

        <Route path="/tickets" element={<Tickets />} />
      </Route>

      <Route
        path="/welcome/notpermission"
        element={
          <RedirectOnAuth>
            <Welcome />
          </RedirectOnAuth>
        }
      />

      <Route path="/portal" element={<Portal />} />

      <Route path="/evento/:id" element={<PublicEvent />} />

      <Route path="/portal/details/:idEvent" element={<PortalDetails />} />
    </Routes>
  );
}
