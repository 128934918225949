import {
  Flex,
  Hide,
  // Link,
  Text,
} from '@chakra-ui/react';

import {
  // Link as ReachLink,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '@/hooks/useAuth';
import { formatName } from '@/utils/formatName';
import { AsideNavigation } from '../AsideNavigation';

export function Header() {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <Flex as="header" justify="space-between" align="center">
      {pathname === '/dashboard' && (
        <Text
          fontSize={{ base: 'md', lg: '2xl' }}
          fontWeight="medium"
          color="gray.700"
          display={{ base: 'none', lg: 'flex' }}
        >
          Olá {formatName(user.name)}
        </Text>
      )}

      <Hide above="lg">
        <Text
          fontSize={{ base: 'md', lg: '2xl' }}
          fontWeight="medium"
          color="gray.700"
        >
          Olá {formatName(user.name)}
        </Text>
      </Hide>

      {/* {pathname === '/dashboard' && (
        <Link
          as={ReachLink}
          to="/create/event"
          bg="orange.400"
          color="white"
          h="2.25rem"
          w="9.5rem"
          justifyContent="center"
          alignItems="center"
          rounded="base"
          _hover={{ textDecoration: 'none' }}
          display={{ base: 'none', lg: 'flex' }}
        >
          Novo Evento
        </Link>
      )} */}

      <AsideNavigation device="mobile" />
    </Flex>
  );
}
