import {
  Center,
  Heading,
  Icon,
  // Link,
  Text,
} from '@chakra-ui/react';
// import { Link as ReachLink } from 'react-router-dom';
import { TbDeviceDesktopAnalytics } from 'react-icons/tb';

export function AlertLatestEvent() {
  return (
    <Center
      h={{ base: '100%', sm: '17.8125rem' }}
      bg="white"
      rounded="1.25rem"
      flexDirection="column"
      py={{ base: '4rem', lg: '0rem' }}
      px={{ base: '2rem', lg: '4rem' }}
      textAlign="center"
    >
      <Icon as={TbDeviceDesktopAnalytics} color="blue.500" fontSize="2rem" />

      <Heading
        as="h2"
        fontSize={{ base: '0.8rem', lg: '1rem' }}
        fontWeight="600"
        color="gray.700"
        mt="0.125rem"
      >
        Nenhum evento está acontecendo no momento
      </Heading>

      <Text
        fontWeight="400"
        fontSize={{ base: '0.8rem', lg: '1rem' }}
        mt="1rem"
      >
        O evento mais recente com status em aberto irá aparecer aqui
      </Text>

      {/* <Link
        as={ReachLink}
        to="/create/event"
        mt="1rem"
        bg="orange.400"
        color="white"
        h="2.25rem"
        w="9.5rem"
        justifyContent="center"
        alignItems="center"
        rounded="base"
        _hover={{ textDecoration: 'none' }}
        display={{ base: 'none', lg: 'flex' }}
        fontWeight="500"
      >
        Novo Evento
      </Link> */}
    </Center>
  );
}
