import { AboutEventState } from '@/store/update-event/@types/about-event';

export const initialAboutEventState: AboutEventState = {
  name: '',
  description: '',
  selectedCategory: {
    id: '',
    name: '',
  },
  selectedCompany: {
    id: '',
    name: '',
    url_logo: '',
    url_sm_logo: '',
    company_stores: [
      {
        id: '',
        name: '',
        store_id: '',
      },
    ],
  },
  selectedRepairTeam: {
    id: '',
    name: '',
  },
  alert_id: null,
  alert: null,
};
