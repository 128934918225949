import { Box, Grid } from '@chakra-ui/react';

import { HeadingTwo } from '@/components/Heading';
import { useUpdateEventStore } from '@/store/update-event';
import { SelectableButton } from '@/components/SelectableButton';
// import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

export function Category() {
  const { selectedCategory, onSelectCategory } = useUpdateEventStore(
    (state) => state,
  );

  // const { queryData } = useGetEventDetailToUpdate();

  const gridColumns = {
    base: 'repeat(2, 1fr)',
    sm: 'repeat(4, 7.875rem)',
    md: 'repeat(4, 6.875rem)',
  };

  return (
    <Box p="2rem 1.5rem" border="1px solid #E5E7EA" rounded="0.5rem">
      <HeadingTwo text="Categoria" />

      <Grid
        templateColumns={gridColumns}
        gap={{ base: 4, sm: 0, md: 4 }}
        mt="1rem"
      >
        {/* {queryData.parameters.categories.map((category) => {
          return (
            <SelectableButton
              key={category.id}
              name={category.name}
              comparisonData={category.id}
              selectedData={selectedCategory.id}
              onSelect={() => onSelectCategory(category)}
            />
          );
        })} */}
        <SelectableButton
          key={selectedCategory.id}
          name={selectedCategory.name}
          comparisonData={selectedCategory.id}
          selectedData={selectedCategory.id}
          onSelect={() => onSelectCategory(selectedCategory)}
        />
      </Grid>
    </Box>
  );
}
