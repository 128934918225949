import {
  Avatar,
  Circle,
  Flex,
  Icon,
  Image,
  Link as LinkChakra,
  Text,
} from '@chakra-ui/react';
import { memo } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { VscCalendar } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { GoHash } from 'react-icons/go';

import { formatDateDayMonthYear } from '@/utils/formatDate';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

function SnapEventInfoComponent() {
  const { queryData } = useGetEventDetailToUpdate();

  return (
    <Flex gap={{ base: '0.6rem', md: '2rem', xl: '3rem' }} wrap="wrap">
      {queryData.event.alert_id && (
        <Flex gap="0.25rem" align="center" textAlign="center">
          <Circle bg="#F5F6F6" minW="2rem" minH="2rem" maxH="2rem" maxW="2rem">
            <Icon as={GoHash} fontSize={16} strokeWidth="0.5" />
          </Circle>

          <Text fontSize="1rem" textAlign="center" display="flex" h="1.1rem">
            ID Alerta {queryData.event.alert_id}
          </Text>
        </Flex>
      )}

      <Flex gap="0.25rem" align="center">
        <Icon as={VscCalendar} fontSize={24} />
        <Text fontSize="1rem">{`Criado em ${formatDateDayMonthYear(
          queryData.event.created_at,
        )}`}</Text>
      </Flex>
      <Flex gap="0.25rem" align="center" justifyContent="center">
        <Avatar name={queryData.event.users.name} size="sm" />

        <Text fontSize="1rem">{queryData.event.users.name}</Text>
      </Flex>
      <Flex gap="0.25rem" align="center" justifyContent="center">
        <Flex
          boxShadow="0px 1px 6px 0px #00000033"
          rounded="full"
          padding="0.5rem"
        >
          <Image
            src={queryData.event.companies.url_sm_logo}
            alt="logo image"
            boxSize="1rem"
          />
        </Flex>

        <Text fontSize="1rem">{queryData.event.companies.name}</Text>
      </Flex>
      <LinkChakra
        as={Link}
        display="flex"
        to={`/evento/${queryData.event.HtmlPage.name}`}
        alignItems="center"
        isExternal
      >
        <Icon as={AiOutlineLink} />
        Ver página do evento
      </LinkChakra>
    </Flex>
  );
}

export const SnapEventInfo = memo(SnapEventInfoComponent);
