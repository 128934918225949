import { apiAxios } from '@/lib/axios/apiAxios';
import { usePortalEventStore } from '@/store/portal-event';
import { formatDateWithHour, formatElapsedTime } from '@/utils/formatDate';
import { getStatusTheme } from '@/utils/getStatusTheme';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Request, Response } from './types';
import { queryClient } from '@/services/queryClient';

export function useGetPortalEvents() {
  const {
    currentPage,
    selectedCompany,
    nameEvent,
    intervalDates,
    selectedCategory,
    changeCurrentPage,
  } = usePortalEventStore((state) => state);

  const query = useQuery({
    queryKey: ['portal-events', currentPage],
    queryFn: async () => {
      try {
        const response = await apiAxios.get<Request>('/portal/event', {
          params: {
            search: nameEvent,
            limit: 10,
            page: currentPage,
            filter: selectedCompany,
            initial_date: intervalDates.initialDate,
            end_date: intervalDates.endDate,
            category: selectedCategory,
          },
        });

        const transformedEvents = response.data.events.map((event) => {
          return {
            ...event,
            createdIn: formatDateWithHour(event.created_at),
            elapsedTime: formatElapsedTime(event.created_at),
            status: {
              currentStatus: event.history_events[0].status.name,
              theme: getStatusTheme(event.history_events[0].status.name),
            },
          };
        });

        changeCurrentPage(response.data.pagination.currentPage);

        return {
          events: transformedEvents,
          pagination: response.data.pagination,
        };
      } catch (error) {
        console.error(error);
        toast.error('Erro na requisição');
      }
    },
  });

  const queryData = queryClient.getQueryData([
    'portal-events',
    currentPage,
  ]) as Response;

  return { ...query, queryData };
}
