import { Flex, Tag, Text } from '@chakra-ui/react';
// import { differenceInHours } from 'date-fns';

// import { ReopenModal } from './ReopenModal';
// import { useLocation } from 'react-router-dom';

interface StatusTagProps {
  historyEvent: {
    status: {
      name: string;
      background: string;
      color: string;
    };
    created_at: string;
  };
}
export function StatusTag({ historyEvent }: StatusTagProps) {
  // const { pathname } = useLocation();

  // const isPortalPage = pathname.startsWith('/portal/details');

  // function onResolvedStatusCheck(statusName: string, createdAt: string) {
  //   if (statusName !== 'Resolvido') {
  //     return false;
  //   } else {
  //     const createdDate = new Date(createdAt);
  //     const currentDate = new Date();
  //     const hoursDifference = differenceInHours(currentDate, createdDate);

  //     const isTimeLessThanFourHours = hoursDifference < 4;

  //     return isTimeLessThanFourHours;
  //   }
  // }

  // const isVisibleReopenButton = onResolvedStatusCheck(
  //   historyEvent.status.name,
  //   historyEvent.created_at,
  // );

  return (
    <Flex gap="1rem" align="center">
      <Tag
        bg={historyEvent.status.background}
        borderRadius="0.875rem"
        padding="0.25rem 1.25rem"
        w="max-content"
      >
        <Text
          fontWeight={500}
          fontSize={{ base: '.875rem', md: '1rem' }}
          color={historyEvent.status.color}
        >
          {historyEvent.status.name}
        </Text>
      </Tag>

      {/* {isVisibleReopenButton && !isPortalPage && <ReopenModal />} */}
    </Flex>
  );
}
