import { AlertEventBox } from '@/components/AlertEventBox';
import { ButtonGoBack } from '@/components/ButtonGoBack';
import { HeadingOne } from '@/components/Heading';
import { Box, Flex } from '@chakra-ui/react';

export function NoNewEvents() {
  return (
    <Box
      my={{ base: '6', lg: '6' }}
      px={{ base: '4', md: '8', lg: '10' }}
      h="100%"
    >
      <Box mt={{ base: '2rem', lg: '0' }} mb="3rem">
        <ButtonGoBack />

        <HeadingOne text="Eventos em aberto" mb="2rem" />

        <Flex flexDirection="column" gap={{ base: '1.2rem', lg: '0.5rem' }}>
          <AlertEventBox
            title="Nenhum evento em aberto no momento."
            description="Os eventos em aberto serão exibidos aqui automaticamente, conforme registrados pela Hubsoft."
          />
        </Flex>
      </Box>
    </Box>
  );
}
