import { FormLabel, Input, Text } from '@chakra-ui/react';

import { useUpdateEventStore } from '@/store/update-event';

export function EventNameInput() {
  const {
    name,
    // onWriteName
  } = useUpdateEventStore((state) => state);

  return (
    <FormLabel w="100%">
      <Text color="gray.700" fontWeight="500">
        Nome do evento
      </Text>

      <Input
        value={name}
        fontSize={{ base: '1.3rem', lg: '1rem' }}
        placeholder={'Informe o nome do evento'}
        _focusVisible={{ padding: '1rem', border: '0.0625rem solid #E5E7EA' }}
        _placeholder={{ color: 'gray.400' }}
        borderColor="#E5E7EA"
        disabled
        _disabled={{
          opacity: 1,
          border: 0,
          px: 0,
        }}
        // onChange={(e) => onWriteName(e.target.value)}
      />
    </FormLabel>
  );
}
