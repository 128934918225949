import { useDetailEventStore } from '@/store/detail-event';
import { Flex, Tag, Text } from '@chakra-ui/react';
// import { AreaDevice } from './AreaDevice';

// interface Items {
//   id: string;
//   description: string;
//   name: string;
// }

export function AlertInfo() {
  const alert = useDetailEventStore((state) => state.event.alert);

  // const renderAreaDevice = (name: string, items: Items[]) => {
  //   return items.length > 0 && <AreaDevice name={name} items={items} />;
  // };

  return (
    <Flex
      w="100%"
      bg="white"
      flexDirection="column"
      borderRadius=".75rem"
      p={{ base: '1.5rem 1.625rem', lg: '1.375rem 2rem' }}
    >
      <Text
        as="span"
        color="gray.900"
        mb="1rem"
        fontWeight={{ base: '700', lg: '500' }}
      >
        Locais e Equipamentos
      </Text>

      {alert.bairros.length > 0 && (
        <Flex
          flexWrap="wrap"
          marginBottom="0.5rem"
          alignItems="center"
          gap="0.5rem"
        >
          <Text>Bairros</Text>

          {alert.bairros.map((item) => (
            <Tag
              key={item}
              variant="solid"
              colorScheme="blue"
              borderRadius="full"
            >
              {item}
            </Tag>
          ))}
        </Flex>
      )}

      {alert.cidades.length > 0 && (
        <Flex
          flexWrap="wrap"
          marginBottom="0.5rem"
          alignItems="center"
          gap="0.5rem"
        >
          <Text>Cidades</Text>

          {alert.cidades.map((item) => (
            <Tag
              key={item.id_cidade}
              variant="solid"
              colorScheme="blue"
              borderRadius="full"
            >
              {item.display}
            </Tag>
          ))}
        </Flex>
      )}

      {alert.caixa_optica.length > 0 && (
        <Flex
          flexWrap="wrap"
          marginBottom="0.5rem"
          alignItems="center"
          gap="0.5rem"
        >
          <Text>CTO</Text>

          {alert.caixa_optica.map((item) => (
            <Tag
              key={item.id_caixa_optica}
              variant="solid"
              colorScheme="blue"
              borderRadius="full"
            >
              {item.display}
            </Tag>
          ))}
        </Flex>
      )}

      {alert.interfaces_conexao.length > 0 && (
        <Flex
          flexWrap="wrap"
          marginBottom="0.5rem"
          alignItems="center"
          gap="0.5rem"
        >
          <Text>Interfaces</Text>

          {alert.interfaces_conexao.map((item) => (
            <Tag
              key={item.id_interface_conexao}
              variant="solid"
              colorScheme="blue"
              borderRadius="full"
            >
              {item.display}
            </Tag>
          ))}
        </Flex>
      )}
    </Flex>
  );
}
