import { EventReturn } from '@/hooks/queries/get-event-detail-to-update/types';

interface RepairTeam {
  id: string;
}

interface Company {
  name: string;
}

interface SelectedCategory {
  id: string;
  name: string;
}

interface StoreState {
  name: string;
  description: string;
  selectedCategory: SelectedCategory;
  selectedRepairTeam: RepairTeam | null;
  selectedCompany: Company | null;
}

export function isEventMatching(
  event: EventReturn,
  // name: string,
  description: string,
): boolean {
  return event.description === description;
  // return event.name === name && event.description === description;
}

export function isDisabledSubmitAboutEvent(
  event: EventReturn,
  store: StoreState,
): boolean {
  const { description, selectedCategory, selectedRepairTeam } = store;

  const eventMatches = isEventMatching(
    event,
    // , name,
    description,
  );

  if (selectedCategory.name === 'Indisponibilidade') {
    if (selectedRepairTeam === null) return true;

    if (selectedRepairTeam !== null && event.repair_team === null) {
      return eventMatches && selectedRepairTeam === null;
    }

    return eventMatches && selectedRepairTeam.id === event.repair_team.id;
  } else {
    return eventMatches;
  }
}
