import { toast } from 'react-toastify';
import { StateCreator } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

import { formatBytes } from '@/utils/formatBytes';
import {
  DetailsResponseParams,
  ImageState,
  ImageActions,
} from '@/store/update-event/@types/image';
import { apiAxios } from '@/lib/axios/apiAxios';
import { initialImageState } from '@/store/update-event/initial-state/image';

export const createImageSlice: StateCreator<ImageState & ImageActions> = (
  set,
  get,
) => ({
  ...initialImageState,
  onSelectImages: (event) => {
    if (!event.target.files) {
      return;
    }

    const currentFiles = [...get().files];
    const currentImages = [...get().uploadedImages];

    const selectedImages = Array.from(event.target.files);
    const sizeArrayImages = selectedImages.length;

    let indexNumber = 0;

    while (indexNumber < sizeArrayImages) {
      currentFiles.push(selectedImages[indexNumber]);
      indexNumber++;
    }

    const convertedFiles = currentFiles.map((image) => {
      return {
        id: uuidv4(),
        imageUrl: URL.createObjectURL(image),
        name: image.name,
        size: formatBytes(image.size),
        lastModified: image.lastModified,
        isUploaded: false,
      };
    });

    const concatImagesApiWithPreview = currentImages.concat(convertedFiles);

    set(() => ({
      files: currentFiles,
      previewImages: convertedFiles,
      allImages: concatImagesApiWithPreview,
    }));
  },
  onDeleteUploadedImage: async (image) => {
    try {
      const response = await apiAxios.delete('event/media', {
        data: {
          id: image.id,
        },
      });

      const currentUploadedImages = [...get().uploadedImages];

      const currentPreviewImage = [...get().preview];

      const imagesNotDeleted = currentUploadedImages.filter((item) => {
        return item.id !== image.id;
      });

      const concatImagesApiWithPreview =
        imagesNotDeleted.concat(currentPreviewImage);

      set(() => ({
        uploadedImages: imagesNotDeleted,
        allImages: concatImagesApiWithPreview,
      }));

      toast.success(response.data.msg);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao excluir imagem');
    }
  },
  onRemoveSelectedImage: (lastModified) => {
    const currentFiles = [...get().files];
    const currentPreview = [...get().preview];
    const currentUploadedImages = [...get().uploadedImages];

    const indexFile = currentFiles.findIndex(
      (item) => item.lastModified === lastModified,
    );

    const indexPreview = currentPreview.findIndex(
      (item) => item.lastModified === lastModified,
    );

    currentFiles.splice(indexFile, 1);
    currentPreview.splice(indexPreview, 1);

    const concatImagesApiWithPreview =
      currentUploadedImages.concat(currentPreview);

    set(() => ({
      files: currentFiles,
      preview: currentPreview,
      allImages: concatImagesApiWithPreview,
    }));
  },
  onSubmit: async ({ eventId, userId }) => {
    try {
      set(() => ({
        isLoading: true,
      }));

      const newImages = new FormData();

      get().files.forEach((image) => {
        newImages.append('media', image);
      });

      const uploadResponse = await apiAxios.post(
        `event/media/upload?event_id=${eventId}&user_id=${userId}`,
        newImages,
      );

      const detailsResponse = await apiAxios.post<DetailsResponseParams>(
        'event/detail',
        {
          event_id: eventId,
        },
      );

      const { event } = detailsResponse.data;

      const parsedImageApi = event.images.map((image) => {
        return {
          ...image,
          imageUrl: image.url,
          isUploaded: true,
        };
      });

      toast.success(uploadResponse.data.msg);

      set(() => ({
        uploadedImages: parsedImageApi,
        allImages: parsedImageApi,
        files: [],
        preview: [],
        isLoading: false,
      }));
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  },
  onAddIsUploadedToImage: (images) => {
    const parsedImages = images.map((image) => {
      return {
        ...image,
        imageUrl: image.url,
        isUploaded: true,
      };
    });

    set(() => ({
      uploadedImages: parsedImages,
      allImages: parsedImages,
    }));
  },
});
