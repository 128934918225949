import { Flex, Image } from '@chakra-ui/react';

interface AvatarCompanyLogoData {
  companyData: {
    companies: {
      url_sm_logo: string;
      name: string;
    };
  };
}

export function AvatarCompanyLogo({ companyData }: AvatarCompanyLogoData) {
  return (
    <Flex
      boxSize={{ base: '2rem', md: '2.8125rem' }}
      minW={{ base: '2rem', md: '2.8125rem' }}
      bg="white"
      boxShadow="0px 1px 6px rgba(0, 0, 0, 0.2);"
      rounded="full"
      align="center"
      justify="center"
    >
      <Image
        src={companyData.companies.url_sm_logo}
        alt={`imagem da logo da ${companyData.companies.name}`}
        boxSize={{ base: '1rem', md: '1.5rem' }}
      />
    </Flex>
  );
}
