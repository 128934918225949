// import { Fragment } from 'react';
import { Box, Button, Grid } from '@chakra-ui/react';

import { HeadingTwo } from '@/components/Heading';
import { useUpdateEventStore } from '@/store/update-event';
// import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

export function Company() {
  const { selectedCompany, onSelectCompany } = useUpdateEventStore(
    (state) => state,
  );

  // const { queryData } = useGetEventDetailToUpdate();

  const templateGridColumnsResponsive = {
    base: 'repeat(2, 1fr)',
    sm: 'repeat(4, 7.875rem)',
    md: 'repeat(4, 6.875rem)',
  };

  return (
    <Box p="2rem 1.5rem" border="1px solid #E5E7EA" rounded="0.5rem">
      <HeadingTwo text="Empresa" />

      <Grid
        templateColumns={templateGridColumnsResponsive}
        gap={{ base: 4, sm: 0, md: 4 }}
        mt="1rem"
      >
        {/* {queryData.parameters.company.map((company) => (
          <Fragment key={company.id}>
            <Button
              color={
                selectedCompany && company.id === selectedCompany.id
                  ? 'blue.500'
                  : 'gray.700'
              }
              rounded="full"
              bg={
                selectedCompany && company.id === selectedCompany.id
                  ? 'blue.100'
                  : 'gray.200'
              }
              h="1.687rem"
              w={{ base: '100%', sm: '7.25rem' }}
              fontWeight="500"
              fontSize="0.875rem"
              _hover={{ filter: 'brightness(0.9)' }}
              onClick={() => onSelectCompany(company)}
            >
              {company.name}
            </Button>
          </Fragment>
        ))} */}
        <Button
          color="blue.500"
          rounded="full"
          bg="blue.100"
          h="1.687rem"
          w={{ base: '100%', sm: '7.25rem' }}
          fontWeight="500"
          fontSize="0.875rem"
          _hover={{ filter: 'brightness(0.9)' }}
          onClick={() => onSelectCompany(selectedCompany)}
        >
          {selectedCompany.name}
        </Button>
      </Grid>
    </Box>
  );
}
