import { Box, Flex, Text, Link, List, Icon, Tag } from '@chakra-ui/react';

import { BiLinkExternal } from 'react-icons/bi';
import { useDetailEventStore } from '@/store/detail-event';
import { Info } from './Info';

export function QuickEventStatus() {
  const { event, currentEventStatus, fixedEvent } = useDetailEventStore(
    (state) => {
      const currentEventStatus = state.event.history_events[0].status.name;
      const fixedEvent = state.event.history_events[0].created_in;

      return { ...state, currentEventStatus, fixedEvent };
    },
  );

  return (
    <Box
      bg="white"
      borderRadius="0.75rem"
      p={{ base: '1.5rem 1.625rem', lg: '1.375rem 2rem' }}
      mb={{ base: 'unset', md: '2rem' }}
    >
      <List
        display="flex"
        flexDir="column"
        color="gray.900"
        gap={{ base: '.875rem', lg: '1.25rem' }}
      >
        <Info name="Status:">
          <Flex w="9rem" alignItems="flex-start">
            <Tag
              background={
                currentEventStatus === 'Resolvido' ? '#AAF4C9' : '#FFD0B5'
              }
              borderRadius="6.25rem"
              px="0.8125rem"
              color={
                currentEventStatus === 'Resolvido' ? 'green.600' : '#F07F3F'
              }
              justifyContent="center"
              fontSize={{ base: '.875rem', md: '1rem' }}
              fontWeight={400}
            >
              {currentEventStatus === 'Resolvido'
                ? currentEventStatus
                : 'Em Aberto'}
            </Tag>
          </Flex>
        </Info>

        <Info name="Tipo do Evento:" description={event.categories.name} />

        {event.alert_id && (
          <Info name="ID do Alerta:" description={String(event.alert_id)} />
        )}

        <Info name="Autor:" description={event.users.name} />

        {event.repair_team && (
          <Flex as="li" justifyContent="space-between">
            <Text as="b" fontWeight="500">
              Parceiro:
            </Text>
            <Text color="gray.400" as="span" w="9rem" alignItems="flex-start">
              {event.repair_team.name}
            </Text>
          </Flex>
        )}

        <Info name="Criado em:" description={event.create_date} />

        {currentEventStatus === 'Resolvido' && (
          <>
            <Info
              name="Encerrado em:"
              description={currentEventStatus === 'Resolvido' && fixedEvent}
            />

            <Info
              name="SLA:"
              description={`${event.sla.hours}h${event.sla.minutes}m`}
            />
          </>
        )}
      </List>

      <Link
        href={event.HtmlPage.html_url}
        display="flex"
        mt="2.5rem"
        gap="0.3rem"
        alignItems="center"
        width="10.3rem"
        color="blue.500"
        isExternal
      >
        <Icon as={BiLinkExternal} size={20} />

        <Text as="span" fontSize="1rem">
          Abrir o link do evento
        </Text>
      </Link>
    </Box>
  );
}
